@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 255 76% 27%;

    --card: 0 0% 100%;
    --card-foreground: 255 76% 27%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 255 91% 55%;
    --primary-foreground: 0 0% 100%;

    --secondary: 245 85% 95%;
    --secondary-foreground: 255 74% 18%;

    --muted: 214 32% 91%;
    --muted-foreground: 255 20% 51%;

    --accent: 245 85% 95%;
    --accent-foreground: 255 91% 55%;

    --status: 78 92% 95%;
    --status-active: 82 85% 67%;
    --status-foreground: 85 85% 35%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 100%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --dashboard: 210 33% 95%;
    --dashboard-foreground:  210 33% 95%;

    --warning:hsla(50, 98%, 64%, 1);

    --reading:201 90% 27%;


  }

  .dark {
    --background: 255 76% 27%;
    --foreground: 0 0% 100%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 255 76% 27%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 215 20% 65%;
    --muted-foreground: 214 32% 91%;

    --accent: 255 91% 55%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: hsl(212.7, 26.8%, 83.9);

    --dashboard:  210 33% 95%;
    --dashboard-foreground:  210 33% 95%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

  [cmdk-list] {
    scroll-padding-block-start: 8px;
    scroll-padding-block-end: 8px;
  }
}

@layer utilities {
  .border-image {
    --border-width: 8px;

    -webkit-filter: drop-shadow(0 var(--border-width) 0 white)
      drop-shadow(0 calc(1 * var(--border-width)) 0 white);
    filter: drop-shadow(0 var(--border-width) 0 white)
      drop-shadow(0 calc(1 * var(--border-width)) 0 white);

    @media screen(md) {
      --border-width: 48px;
    }
  }

  .perspective {
    perspective: 1000px;
  }

  .rounded-max {
    border-radius: 2.5rem;
    @media screen(md) {
      border-radius: 5rem;
    }
  }

  .rounded-dot {
    @apply overflow-hidden rounded-tl-full rounded-bl-full rounded-br-full rounded-tr-none;
  }
  .rounded-dot--inverted {
    @apply rounded-tr-full rounded-br-full rounded-tl-none;
  }

  .truncate-line-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  ul {
    list-style: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ol {
    list-style: auto;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  iframe {
    width: 100%;
  }

}
.table-dapper {
  @apply border border-muted border-separate rounded-lg;
  tr {
    td {
      @apply text-sm px-4 py-2;
    }
  }
  tr:not(:first-child) {
    td {
      @apply border-t;
    }
  }
  thead {
    @apply text-left;
    th {
      @apply text-sm px-4 py-2 font-medium border-b;
    }
  }
}
.popoverContentFull{
  width: var(--radix-popover-trigger-width);
    max-height: var(--radix-popover-content-available-height);
}



.scroll-nav{
  scrollbar-width: thin;
  scrollbar-color: hsl(var(--muted)) transparent;
}

.scroll-nav::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

.scroll-nav::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

.scroll-nav::-webkit-scrollbar-thumb {

  @apply bg-muted;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid transparent;
  /* creates padding around scroll thumb */
}
[data-radix-popper-content-wrapper] {
  min-width: auto !important;
}

img {
  display: inline-block;
}

